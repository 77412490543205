import React from "react";
import { graphql, Link } from "gatsby";
import { BsArrowRight } from "react-icons/bs";

import Layout from "components/bespoke/Layout";
import Image from "components/Image";
import Logo from "../../images/LogoShape";
import Jumbotron from "../../components/Jumbotron";

export const query = graphql`
  {
    wp {
      page(id: "/bespoke/approach/", idType: URI) {
        id
        title
        slug
        featuredImage {
          node {
            ...MediaItem
          }
        }
        approach {
          topPanel {
            content
            heading
            image {
              ...MediaItem
            }
          }
          jumbotron {
            link {
              target
              url
              title
            }
            heading
            image {
              ...MediaItem
            }
          }
          howWeWork {
            bullets {
              bullet
            }
            label
            image {
              ...MediaItem
            }
          }
        }
      }
    }
  }
`;

const Approach = ({ data }) => {
  const { title, featuredImage, approach } = data.wp.page;

  return (
    <Layout title={title}>
      <div className="bg-two-tone from-firefly to-white">
        <div className="md:container mx-6 md:mx-auto bg-white">
          {featuredImage && (
            <div className="">
              <Image image={featuredImage.node} />
            </div>
          )}
          <div className="mt-8 grid md:grid-cols-2 gap-4">
            <div className="flex flex-col space-y-4 px-6 md:px-12 md:py-12 md:pr-16">
              <div className="text-3xl font-bold tracking-wider text-firefly uppercase">
                {approach.topPanel.heading}
              </div>
              <div
                className="font-light tracking-wider text-mineral"
                dangerouslySetInnerHTML={{ __html: approach.topPanel.content }}
              ></div>
            </div>
            <div className="flex flex-col md:mr-12">
              <Image image={approach.topPanel.image} />
              <Link to={`/bespoke/projects`}>
                <div className="py-6 text-stormdust uppercase text-xl tracking-wider font-bold flex items-center justify-end">
                  View projects
                  <BsArrowRight className={`ml-4 text-3xl text-firefly`} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="md:container mx-6 md:mx-auto bg-white">
        <div className="relative py-12">
          <hr />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 w-20 h-20 grid place-items-center bg-white p-4">
            <Logo />
          </div>
        </div>
        <h2 className="my-12 text-4xl font-bold tracking-wider text-firefly uppercase text-center">
          How we work
        </h2>
        {approach.howWeWork && (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
            {approach.howWeWork.map(({ bullets, label, image }, i) => {
              return (
                <div key={i} className="flex flex-col bg-gray-100">
                  <div className=" py-8 px-7">
                    <div className="font-bold text-xl text-firefly-500 uppercase mb-4">
                      {label}
                    </div>
                    {bullets && (
                      <ul className="">
                        {bullets.map(({ bullet }, i) => {
                          return (
                            <li
                              key={i}
                              className="font-light text-stormdust text-lg flex gap-2 mb-2 leading-normal "
                            >
                              <div>&ndash;</div> {bullet}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                  <div className="flex mt-auto h-64 lg:h-96">
                    <Image className="object-cover" image={image} />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {approach.jumbotron.image && (
        <a href="/bespoke/projects">
          <Jumbotron
            heading={approach.jumbotron.heading}
            image={approach.jumbotron.image}
          />
        </a>
      )}
    </Layout>
  );
};

export default Approach;
